import qs from 'query-string';
import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { Alert as PolarisAlert, Button, ColumnLayout, ExpandableSection, Form, FormField, FormSection, Select, Spinner, Textarea } from '@amzn/awsui-components-react';
import * as QuestionnaireApi from '../../api/QuestionnaireApi';
import { Alert, FlashContextInterface, withFlashContext } from '../../context/FlashContext';
import { Answer } from '../../types/Answer';
import { FlashType } from '../../types/Flash.d';
import { Question } from '../../types/Question';
import { Questionnaire } from '../../types/Questionnaire';
import { IsUndefinedOrNull } from '../../Utility';
import Breadcrumbs from '../common/Breadcrumbs';
import Auth from '../../Auth/Auth';

interface QuestionViewState {
    questionnaireId: string;
    assignmentId?: string;
    loading: boolean;
    saving: boolean;
    submitting: boolean;
    questionnaire?: Questionnaire;
    questions: Question[];
    answerChanged: boolean;
    valid: boolean;
    viewOnly: boolean;
    userEmail: string;
    isOwner: boolean;
    instructionsVisible: boolean;
}

interface MatchParams {
    id: string;
    assignmentId?: string;
}

// Max SNS notification size is 256KB
// Max MySQL Text size is 64KB
// with UTF-8 and estimating 4byte per character max
// that means we can send 16384 characters and mysql will accept it
// We could use 16000 instead of 16384 because it's a bit more human readable
// but for now we'll use 500
const maxCharacterLength = 500;

class View extends React.Component<RouteComponentProps<MatchParams> & FlashContextInterface, QuestionViewState> {
    private readonly alert: Alert;

    constructor(props: RouteComponentProps<MatchParams> & FlashContextInterface) {
        super(props);
        this.state = {
            questionnaireId: props.match.params.id,
            assignmentId: props.match.params.assignmentId,
            loading: true,
            saving: false,
            submitting: false,
            questionnaire: undefined,
            questions: [],
            answerChanged: false,
            valid: true,
            viewOnly: props.location.pathname.endsWith('/view'),
            userEmail: qs.parse(props.location.search).user_email as string || Auth.getUserEmail(),
            isOwner: false,
            instructionsVisible: true,
        };
        this.alert = props.alert;
        this.handleSave = this.handleSave.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);        
    }

    async componentDidMount() {
        this.loadQuestionnaire();
    }

    loadQuestionnaire = async () => {
        try {
            this.setState({
                ...this.state,
                loading: true,
            })
            const q: Questionnaire = await QuestionnaireApi.getQuestions(this.state.questionnaireId, this.state.assignmentId);
            const currentUserEmail = Auth.getUserEmail();
            this.setState({
                ...this.state,
                loading: false,
                saving: false,
                submitting: false,
                questionnaire: q,
                questions: q.questions,
                answerChanged: false,
                isOwner: this.isOwner(q, currentUserEmail),
            });
        } catch (err: any) {
            console.log(`Encountered error loading questions: ${err.message}`);
            this.setState({
                ...this.state,
                loading: false,
                saving: false,
                submitting: false,
                questionnaire: undefined,
                questions: [],
                answerChanged: false
            });
            if (err.response && err.response.status === 404) {
                this.alert(FlashType.error, 'Questionnaire or questions not found', "Error loading questions. Please make sure the email used to assign the questionnaire matches exactly to your login email");
            } else {
                this.alert(FlashType.error, err.message, "Error loading questions");
            }
        }
    }

    handleInput = (idx: number) => (event: any) => {
        const updated: Question[] = this.state.questions.map((question, qidx) => {
            if (idx === qidx && (event.detail.value.length > maxCharacterLength)) {
                let error = `Max character length is ${maxCharacterLength}, ${event.detail.value.length - maxCharacterLength} over limit`;
                return { ...question, answer: event.detail.value, changed: true, error: error };
            } else if (idx === qidx && (event.detail.value.length <= maxCharacterLength)) {
                return { ...question, answer: event.detail.value, changed: true, error: undefined };
            } else {
                return question;
            }
        });

        const isValid = updated.reduce((valid: boolean, q: Question) => {
            return valid && !q.error;
        }, true);

        this.setState({
            ...this.state,
            questions: updated,
            valid: isValid,
            answerChanged: true,
        });
        this.handleSave();
    };

    handleComment = (idx: number) => (event: any) => {
        const updated: Question[] = this.state.questions.map((question, qidx) => {
            if (idx === qidx && (event.detail.value.length > maxCharacterLength)) {
                let error = `Max character length is ${maxCharacterLength}, ${event.detail.value.length - maxCharacterLength} over limit`;
                return { ...question, comment: event.detail.value, changed: true, error: error };
            } else if (idx === qidx && (event.detail.value.length <= maxCharacterLength)) {
                return { ...question, comment: event.detail.value, changed: true, error: undefined };
            } else {
                return question;
            }
        });

        const isValid = updated.reduce((valid: boolean, q: Question) => {
            return valid && !q.error;
        }, true);

        this.setState({
            ...this.state,
            questions: updated,
            valid: isValid,
            answerChanged: true,
        });
        this.handleSave();
    };

    handleSelect = (idx: number) => (event: any) => {
        const selectedValue: string = event.detail.selectedOption.value;
        const updated: Question[] = this.state.questions.map((question, qidx) => {
            if (idx === qidx && (question.answer !== selectedValue)) {
                return { ...question, answer: selectedValue, changed: true, error: undefined };
            } else {
                return question;
            }
        });

        const isValid = updated.reduce((valid: boolean, q: Question) => {
            return valid && !q.error;
        }, true);

        this.setState({
            ...this.state,
            questions: updated,
            valid: isValid,
            answerChanged: true,
        });
        this.handleSave();
    }

    async handleSave(event?: any) {
        const answeredQuestions = this.state.questions.filter((q: Question) => (q.answer && q.changed) || (q.hint && !q.answer && !q.changed));
        const answers: Answer[] = answeredQuestions.map((q: Question) => {
            const answer: Answer = {
                answer: q.answer || (q.hint && q.hint.toString()),
                comment: q.comment,
                questionId: q.questionId
            };
            return answer;
        });
        try {
            this.setState({
                ...this.state,
                loading: false,
                saving: true,
                submitting: false,
            });
            const q: Questionnaire = await QuestionnaireApi.answerQuestions(this.state.questionnaireId, answers);
            this.setState({
                ...this.state,
                loading: false,
                saving: false,
                submitting: false,
                questionnaire: q,
                questions: q.questions,
                answerChanged: false
            });
        } catch (e) {
            this.setState({
                ...this.state,
                loading: false,
                saving: false,
                submitting: false,
            });
            this.alert(FlashType.error, e instanceof Error ? e.message : 'Exception thrown is not of type Error', "Error saving answers");
        }
    }

    async handleSubmit(event: any) {
        const { questions } = this.state;
        const answeredQuestions = questions.filter((q: Question) => (q.answer && q.changed) || (q.hint && !q.answer && !q.changed));
        const requiredQuestionsAnswered = questions.reduce((answered: boolean, q: Question) => {
            if (answered && q.required && !q.answer && !q.hint) {
                return false;
            }
            else return answered;
        }, true);
        const answers: Answer[] = answeredQuestions.map((q: Question) => {
            const answer: Answer = {
                answer: q.answer || (q.hint && q.hint.toString()),
                comment: q.comment,
                questionId: q.questionId
            };
            return answer;
        });
        try {
            this.setState({
                ...this.state,
                loading: false,
                saving: !requiredQuestionsAnswered,
                submitting: requiredQuestionsAnswered,
            });
            const q: Questionnaire = requiredQuestionsAnswered ? 
                await QuestionnaireApi.submitQuestions(this.state.questionnaireId, answers) :
                await QuestionnaireApi.answerQuestions(this.state.questionnaireId, answers);
            this.setState({
                ...this.state,
                loading: false,
                saving: false,
                submitting: false,
                questionnaire: q,
                questions: q.questions,
                answerChanged: false
            });
            if (requiredQuestionsAnswered) {
                const title = (this.state.questionnaire && this.state.questionnaire.title) || '';
                this.alert(FlashType.success, `Answers submitted for questionnaire ${title}`, '');
                this.props.history.push('/questionnaires');
            }
            else {
                this.alert(FlashType.error, "Required question(s) not answered", "Error submitting answers");
            }
        } catch (e) {
            this.setState({
                ...this.state,
                loading: false,
                saving: false,
                submitting: false,
            });
            this.alert(FlashType.error, e instanceof Error ? e.message : 'Exception thrown is not of type Error', "Error submitting answers");
        }
    }

    hideInstructions = () => {
        this.setState({...this.state, instructionsVisible: false});
    }

    renderInstructions() {
        return (
            <PolarisAlert onDismiss={this.hideInstructions} visible={this.state.instructionsVisible} dismissible header="Instructions">
                Use this page to provide answers to the questionnaire. If you cannot complete the questionnaire in one session, 
                your answers are saved automatically. You can also <b>Save</b> manually to document your responses in the system and complete it later. 
                Once you provide responses to all required questions (marked with asterisks *), click <b>Submit</b> to complete the questionnaire.
            </PolarisAlert>
        );
    }

    // If the previous answers are showing incomplete, autosaved answers, then
    // change the service code to put only submitted answers in `previousAnswers`
    // MDC Service - service/src/questionnaires/questionnaire.service.ts:956
    renderPreviousAnswerItems(previousAnswers: string[]) {
        const previousAnswerItems = previousAnswers.map((a, idx) => {
            return (<li key={idx}>{a}</li>);
        });
        return previousAnswerItems;
    }
 
    renderPreviousAnswers(previousAnswers: string[]) {
        return (
            <ul>
                {this.renderPreviousAnswerItems(previousAnswers)}
            </ul>
        );
    }

    renderInputField(q: Question, idx: number) {
        const comment = q.allowComment ? (
            <div className='awsui-util-pt-m'>
                <Textarea
                    disabled={!q.answer /* Allow commenting after the user selects an answer */}
                    value={(!IsUndefinedOrNull(q.comment) ? q.comment : '')}
                    onChange={this.handleComment(idx)}
                    placeholder='Optional Comment'
                ></Textarea>
            </div>
        ) : null;
        if (q.answerType === 'select' && q.possibleAnswers && q.possibleAnswers.length) {
            let selectedId: string = '';
            const options: (Select.IOption | Select.Option | Select.OptionsGroup)[] = q.possibleAnswers.map((a, idx) => {
                if (q.answer === a.value) {
                    selectedId = idx.toString()
                }
                return {
                    label: a.label,
                    value: a.value,
                    id: idx.toString()
                };
            });
            return (
                <span>
                    <Select
                        options={options}
                        selectedId={selectedId}
                        onChange={this.handleSelect(idx)}
                        disabled={this.state.viewOnly}
                    ></Select>
                    {comment}
                </span>
            )
        } else {
            return (
                <span>
                    <Textarea
                        value={(q.answer ?? (this.state.viewOnly ? undefined : q.hint?.toString()))}
                        onChange={this.handleInput(idx)}
                        disabled={this.state.viewOnly}
                    ></Textarea>
                    {comment}
                </span>
            );
        }
    }

    renderQuestionFields() {
        const questionFields = this.state.questions.map((q, idx) => {
            const label = q.required ? `${q.questionText} *` : q.questionText;
            return (
                <FormSection
                    key={q.questionId}
                    header={q.label ? q.label : ""}
                    footer={q.previousAnswers?.length && !this.state.viewOnly ?
                        (<ExpandableSection header={`${q.previousAnswers.length} Previous Answers`} variant="borderless">
                            <ColumnLayout>
                                <div data-awsui-column-layout-root="true">
                                    <div>
                                    {this.renderPreviousAnswers(q.previousAnswers)}
                                    </div>
                                </div>
                            </ColumnLayout>
                        </ExpandableSection>) :
                        undefined
                    }
                >
                    <ColumnLayout>
                        <div data-awsui-column-layout-root="true">
                            <div>
                                <FormField
                                    label={label}
                                    description={q.description}
                                    hintText={(IsUndefinedOrNull(q.answer) && q.hint && q.hintLabel) ? q.hintLabel : undefined }
                                    errorText={q.error}>
                                        {this.renderInputField(q, idx)}
                                </FormField>
                            </div>
                        </div>
                    </ColumnLayout>
                </FormSection>
            );
        });
        return questionFields;
    }

    hintsAreUnsaved() {
        let questions = ((this.state.questionnaire && this.state.questionnaire.questions) || []);
        let unsaved = questions.reduce((prev: boolean, q: Question) => {
            return prev || ((!!q.hint) && (!q.answer));
        }, false);
        return unsaved;
    }

    renderQuestions() {
        const resubmit = !!(this.state.questionnaire && this.state.questionnaire.submitted);
        return (
            <Form
                header={this.state.questionnaire ? this.state.questionnaire.title : 'Untitled'}
                description={this.state.questionnaire ? this.state.questionnaire.description : ''}
                actions={this.state.viewOnly ? (<div>
                    <Button text="Cancel" onClick={() => this.props.history.push(`/questionnaires/${this.props.match.params.id}/participants`)} loading={this.state.saving}></Button>
                </div>) : (<div>
                    <Button text="Save" onClick={this.handleSave} disabled={(!this.hintsAreUnsaved() && !this.state.answerChanged) || this.state.submitting || !this.state.valid} loading={this.state.saving}></Button>
                    <Button text={resubmit ? "Resubmit" : "Submit"} onClick={this.handleSubmit} disabled={this.state.saving || !this.state.valid} loading={this.state.submitting} variant="primary"></Button>
                </div>)}
                >
                    {this.renderQuestionFields()}
                    <p className='awsui-util-f-l'>* - Required</p>
            </Form>
        );
    }

    renderNoQuestionnaire() {
        return (
            <div>
                <h1>No Questionnaire Loaded</h1>
                <Button text="Retry" onClick={this.loadQuestionnaire}></Button>
            </div>
        );
    }

    render() {
        const items = [{text: 'Questionnaires', href: '/questionnaires'}];
        if (this.state.isOwner) {
            items.push({text: `${this.state.questionnaire?.title}`, href: `/questionnaires/${this.state.questionnaireId}/participants`});
        }
        items.push({text: this.state.userEmail, href: `/questionnaires/${this.state.questionnaireId}`});
        if (this.state.loading) {
            return (<Spinner />);
        } else if (this.state.questionnaire) {
            return (
                <span>
                    <Breadcrumbs {...this.props} items={items}></Breadcrumbs>
                    {this.renderInstructions()}
                    <div className="awsui-util-container">
                        <div>
                            {this.renderQuestions()}
                        </div>
                    </div>
                </span>
            );
        } else {
            return this.renderNoQuestionnaire();
        }
    }

    isOwner(q: Questionnaire, userEmail: string): boolean {
        if(!userEmail) {
            return false;
        }
        const lowerCaseUserEmail = userEmail.toLowerCase();
        if(q?.owner?.toLowerCase() === lowerCaseUserEmail) {
            return true;
        }
        const isOwner = q?.owners?.some(owner => owner.toLowerCase() === lowerCaseUserEmail) || false;
        return isOwner;
    }
}

export default withFlashContext(View);