import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Authentication } from './Auth/Auth';
import FAQ from './components/FAQ';
import Login from './components/Login';
import Logout from './components/Logout';
import Landing from './components/Landing';
import QuestionnaireRoutes from './components/questionnaires/Routes';
import Terms from './components/Terms';
import PrivateRoute from './PrivateRoute';

class Routes extends React.Component<any, any> {
    auth: Authentication;

    constructor(props: any) {
        super(props);
        this.auth = props.auth;
    }

    render() {
        return (
        <Router>
            <div>
                <Route exact path="/" component={Landing} />
                <Route path="/faq" component={FAQ} />
                <Route path="/terms" component={Terms} />
                <Route path="/login" render={(props) => <Login {...props} />} />
                <Route path="/signout" component={Logout} />
                <PrivateRoute path="/questionnaires" component={QuestionnaireRoutes} />
            </div>
        </Router>
        );
    }
}

export default Routes;
