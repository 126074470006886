import * as React from "react";
import { Spinner } from '@amzn/awsui-components-react';
import { Route, RouteProps } from "react-router-dom"
import Auth from "./Auth/Auth";

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
}

class PrivateRoute extends React.Component<PrivateRouteProps> {
    props: PrivateRouteProps;

    constructor(props: PrivateRouteProps) {
      super(props);
      this.props = props;
    }

    render() {
        const { component: Component, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(routeProps) => {
                        if (Auth.isAuthenticated()) {
                            return (<Component {...routeProps} />);
                        } else {
                            console.log("Not authenticated, redirecting to login");
                            Auth.redirectToLogin();
                            return (<Spinner />);
                        }
                    }
                }
            />
        );
    }
}

// <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />

export default PrivateRoute;