import { Alert, Select, Table, TableSelection, TableSorting } from '@amzn/awsui-components-react';
import { Box, Button, Container, Grid, Header, Icon, Modal, Popover, SpaceBetween, StatusIndicator, Textarea } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from "react-router-dom";
import isEmail from 'validator/lib/isEmail';
import Auth from '../../Auth/Auth';
import * as QuestionnaireApi from '../../api/QuestionnaireApi';
import CONSTANTS from '../../constants';
import { FlashContextInterface, withFlashContext } from '../../context/FlashContext';
import { Assignment } from '../../types/Assignment';
import { FlashType } from '../../types/Flash.d';
import { Questionnaire } from '../../types/Questionnaire';
import Breadcrumbs from '../common/Breadcrumbs';

interface MatchParams {
    id: string;
}

function Participants(props: RouteComponentProps<MatchParams> & FlashContextInterface) {
    const questionnaireId = props.match.params.id;
    const [loading, setLoading] = useState(true);
    
    const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
    async function getQuestionnaire() {
        setLoading(true);
        try {
            const q = await QuestionnaireApi.getQuestionnaire(questionnaireId);
            setQuestionnaire(q);
        } catch (e) {
            console.log(`Encountered error loading participants: ${e instanceof Error ? e.message : 'Exception thrown is not of type Error'}`);
            setQuestionnaire(undefined);
            props.alert(FlashType.error, e instanceof Error ? e.message : 'Exception thrown is not of type Error', "Error loading questionnaires");
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        getQuestionnaire();
    }, []);

    function displayStatus(status?: string) {
        if (status === "submitted") {
            return <span style={{color: "#1d8102"}}>
                <Icon
                    name="status-positive"
                    size="normal"
                    variant="success"
                />
                &nbsp;Submitted
            </span>
        } else {
            return <span style={{color: "#687078"}}>
                <Icon
                    name="status-pending"
                    size="normal"
                    variant="subtle"
                />
                &nbsp;{ status === "in progress" ? "In progress" : "Ready" }
            </span>
        }
    }
    const columnDefinitions: Table.ColumnDefinition<Table.Item>[] = [
        {
            id: "participant",
            header: "Participant",
            cell: (item: Assignment) => item.userId,
        },
        {
            id: "assignmentDate",
            header: "Assignment Date",
            cell: (item: Assignment) => item.createdDate ? (new Date(item.createdDate)).toDateString() : 'N/A',
        },
        {
            id: "dueDate",
            header: "Due Date",
            cell: () => questionnaire?.dueDate ? (new Date(questionnaire.dueDate)).toDateString() : 'N/A',
        },
        {
            id: "status",
            header: "Status",
            cell: (item: Assignment) => displayStatus(item.status),
        },
    ];
    const sortableColumns: TableSorting.SortableColumn<Table.Item>[] = [
        { id: "participant", field: "participant" },
        { id: "assignmentDate", field: "assignmentDate" },
        { id: "dueDate", field: "dueDate" },
        { id: "status", field: "status" },
    ];
    const features = [
        "selection",
        "sorting",
    ];
    const [selectedParticipants, setSelectedParticipants] = useState<Assignment[]>([]);
    const [participants, setParticipants] = useState<Assignment[]>([]);
    const [filteredParticipants, setFilteredParticipants] = useState<Assignment[]>([]);
    async function getParticipants() {
        setLoading(true);
        setSelectedParticipants([]);
        try {
            const p = await QuestionnaireApi.getParticipants(questionnaireId);
            setParticipants(p);
            setFilteredParticipants(p);
        } catch (e) {
            console.log(`Encountered error loading participants: ${e instanceof Error ? e.message : 'Exception thrown is not of type Error'}`);
            setParticipants([]);
            setFilteredParticipants([]);
            props.alert(FlashType.error, e instanceof Error ? e.message : 'Exception thrown is not of type Error', "Error loading questionnaires");
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        getParticipants();
    }, []);

    const [addModalVisible, setAddModalVisible] = React.useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);

    function sendEmailReminder(participants: Assignment[]) {
        if (questionnaire?.assigneeEmailTemplate) {
            const subject = questionnaire.assigneeEmailTemplate.subject;
            const body = questionnaire.assigneeEmailTemplate.body.replace(/\\n|\\r/g, '\n');
            const addresses = participants.map(p => p.userId).join(';');
            window.open(`mailto:${addresses}?subject=${subject}&body=${encodeURIComponent(body)}`);
        }
    }
    const items = [
        {text: 'Questionnaires', href: '/questionnaires'},
        {text: `${questionnaire?.title}`, href: `/questionnaires/${questionnaireId}/participants`},
    ];

    async function deleteParticipants() {
        try {
            await QuestionnaireApi.deleteParticipants(questionnaireId, selectedParticipants.map(p => p.userId));
            setDeleteModalVisible(false);
            await getParticipants();
        } catch (e) {
            console.error(e instanceof Error ? e.message : e);
        }
    }

    const [currentUserEmail, setCurrentUserEmail] = useState<string>('');
    useEffect(() => {
        try {
            setCurrentUserEmail(Auth.getUserEmail());
        } catch (e) {
            props.alert(FlashType.error, e instanceof Error ? e.message : 'Exception thrown is not of type Error', "Error loading current user email");
        }
    }, []);

    function openQuestionnaireButton() {
        if (selectedParticipants.length === 1 && selectedParticipants[0].userId?.toLowerCase() === currentUserEmail?.toLowerCase()) {
            return (
                <Button onClick={() => props.history.push(`/questionnaires/${questionnaireId}`)}>Open questionnaire</Button>
            );
        } else if (selectedParticipants.length === 1 && selectedParticipants[0].status === 'submitted') {
            return (
                <Button onClick={() => props.history.push(`/questionnaires/${questionnaireId}/assignments/${selectedParticipants[0].id}/view?user_email=${encodeURIComponent(selectedParticipants[0].userId)}`)}>Open questionnaire</Button>
            );
        } else {
            return (
                <Button disabled>Open questionnaire</Button>
            );
        }
    }

    const defaultFilter = {id: 'anyStatus', label: 'Any Status'};
    const statusFilterOptions: Select.Option[] = [
        defaultFilter,
        {id: 'submitted', label: 'Submitted'},
        {id: 'in progress', label: 'In Progress'},
        {id: 'ready', label: 'Ready'}];
    const [selectedStatusFilter, setSelectedStatusFilter] = useState<string>(defaultFilter.id);
    useEffect(() => {
        filterParticipants();
    }, [selectedStatusFilter]);

    function updateStatusFilter(event: CustomEvent<Select.ChangeDetail>) {
        const { selectedId } = event.detail;
        setSelectedStatusFilter(selectedId);
    }

    async function filterParticipants() {
        const updatedParticipants = participants.filter(participant => {
            return selectedStatusFilter === defaultFilter.id || participant.status === selectedStatusFilter;
        });
        setFilteredParticipants(updatedParticipants);
    }

    const [instructionsVisible, setInstructionsVisible] = React.useState(true);
    const Instructions = () => {
        return (
            <Alert onDismiss={() => setInstructionsVisible(false)} visible={instructionsVisible} dismissible header="Instructions">
                Use this page to manage the questionnaire by assigning Participants and monitoring the completion status. 
                If your browser is configured to allow pop-up windows and has a default mail client configured, 
                choosing <b>Add participant(s)</b> will generate an email that can be used to inform Participants about the questionnaire assignment. 
                You can also use the email template below to manually construct a message to your Participants.

                Participants should follow the instructions in the email to sign up with the AWS Business Data Gathering tool and to complete the questionnaire.
                During the sign-up process, Participants must use the same email address you specified when you assigned them to be Participants.
            </Alert>
        );
    }

    const ParticipantsTable = () => {
        return (
            <Table
                loading={loading}
                columnDefinitions={columnDefinitions}
                items={filteredParticipants}
                features={features}
                header={
                    <Header
                        counter={
                            selectedParticipants.length
                                ? "(" + selectedParticipants.length + "/" + participants.length +")"
                                : "(" + participants.length + ")"
                        }
                        variant="h2"
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Select
                                    options={statusFilterOptions}
                                    selectedId={selectedStatusFilter}
                                    onChange={updateStatusFilter}
                                />
                                <Button
                                    disabled={selectedParticipants.length === 0}
                                    onClick={() => setDeleteModalVisible(true)}
                                >
                                    Delete
                                </Button>
                                <Button
                                    disabled={selectedParticipants.length === 0}
                                    onClick={() => sendEmailReminder(selectedParticipants)}
                                >
                                    Send reminder
                                </Button>
                                {openQuestionnaireButton()}
                                <Button
                                    onClick={() => setAddModalVisible(true)}
                                    variant="primary"
                                >
                                    Add participant(s)
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        {CONSTANTS.PARTICIPANT_TABLE_HEADER}
                    </Header>
                }
                empty={<Box textAlign="center">
                    <Box margin={{"bottom":"xxs"}} padding={{"top":"xxs"}}>
                        <b>
                            {!participants.length ? CONSTANTS.PARTICIPANT_TABLE_NO_CONTENT_HEADER : CONSTANTS.PARTICIPANT_TABLE_NO_MATCH_HEADER}
                        </b>
                    </Box>

                    {/* POLARIS_V3.0_MIGRATION_WARNING: `s` size spacing in 2.1 was automatically converted to `s`. You might need to use `xs` instead. Please read Polaris CSS utilties migration guide. */}
                    <Box variant="p" margin={{"bottom":"xs"}}>
                        {!participants.length ? CONSTANTS.PARTICIPANT_TABLE_NO_CONTENT_BODY : CONSTANTS.PARTICIPANT_TABLE_NO_MATCH_BODY}
                    </Box>
                </Box>}
            >
                <TableSelection selectedItems={selectedParticipants} onSelectionChange={e => setSelectedParticipants(e.detail.selectedItems)} trackBy="id"></TableSelection>
                <TableSorting sortableColumns={sortableColumns}></TableSorting>
            </Table>
        );
    }



    const AddModal = () => {
        const [newParticipants, setNewParticipants] = React.useState("");
        const [invalidEmails, setInvalidEmails] = React.useState<string[]>([]);

        const InvalidEmailsWarning = () => {
            if (invalidEmails.length) {
                return (
                    <Box color="text-status-error">
                        <Icon name="status-warning" size="normal" variant="error"/>
                        The following invalid email {invalidEmails.length > 1 ? "addresses are" : "address is"} found:
                        <ul>{invalidEmails.map(email => <li key={email}>{email}</li>)}</ul>
                    </Box>
                );
            }
            return null;
        }

        async function addNewParticipants() {
            try {
                const parsedParticipants = newParticipants.split(';').map(p => p.trim().toLowerCase()).filter(Boolean);
                const invalidParticipants = parsedParticipants.filter(p => !isEmail(p));
                // Display the first five invalid emails
                setInvalidEmails(invalidParticipants.slice(0, 5));
                if (invalidParticipants.length > 0) {
                    throw new Error(`Invalid email addresses: ${invalidParticipants}`);
                }
                const createdParticipants = await QuestionnaireApi.createParticipants(questionnaireId, parsedParticipants);
                setAddModalVisible(false);
                setNewParticipants("");
                sendEmailReminder(createdParticipants);
                await getParticipants();
            } catch (e) {
                console.error(e instanceof Error ? e.message : e);
            }
        }

        return (
            <Modal      
                onDismiss={() => setAddModalVisible(false)}
                visible={addModalVisible}
                size="medium"
                footer={
                    <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setAddModalVisible(false)} variant="link">Cancel</Button>
                        <Button onClick={() => addNewParticipants()} variant="primary">Confirm</Button>
                    </SpaceBetween>
                    </Box>
                }
                header="Add participant(s)"
            >
                Email address
                <Textarea
                    onChange={({detail}) => setNewParticipants(detail.value)}
                    value={newParticipants}
                />
                <InvalidEmailsWarning></InvalidEmailsWarning>
                <Box color="text-status-inactive">Multiple emails must be separated by semicolon.</Box>
            </Modal>
        );
    }

    const DeleteModal = () => {
        return (
            <Modal
                onDismiss={() => setDeleteModalVisible(false)}
                visible={deleteModalVisible}
                size="medium"
                footer={
                    <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setDeleteModalVisible(false)} variant="link">Cancel</Button>
                        <Button
                            onClick={() => deleteParticipants()}
                            variant="primary"
                        >Confirm</Button>
                    </SpaceBetween>
                    </Box>
                }
                header={`Delete ${selectedParticipants.length} participant(s)`}
            >
                After participants are deleted, their answers cannot be recovered. Please confirm this action.
            </Modal>
        );
    }


    const onCopyToClipboardClick = () => {
        //const text: string = questionnaire?.assigneeEmailTemplate?.body || '';
        //var textField = document.createElement('textarea')
        //textField.innerText = text
        //document.body.appendChild(textField)
        //textField.select()
        //document.execCommand('copy')
        //textField.remove()
        const textarea: HTMLTextAreaElement | null = document.querySelector('#email-template-body textarea');
        if (textarea) {
            textarea.select();
            document.execCommand('copy');

            if (window.getSelection) {
                const selection = window.getSelection();
                selection?.removeAllRanges();
            }
        }
    }

    const EmailTemplate = () => {
        const emailBody = questionnaire?.assigneeEmailTemplate?.body || 'No Email Template Specified';
        return (
            <Container
                header={
                    <Header
                        variant="h2"
                        actions={
                            <SpaceBetween
                              direction="horizontal"
                              size="xs"
                            >
                                <Popover
                                    dismissButton={false}
                                    position="top"
                                    size="small"
                                    triggerType="custom"
                                    content={
                                    <StatusIndicator type="success">
                                        Template Copied
                                    </StatusIndicator>
                                    }
                                >
                                    <Button iconName="copy" onClick={onCopyToClipboardClick}>Copy to Clipboard</Button>
                                </Popover>
                            </SpaceBetween>
                          }
                    >
                        Email Template
                    </Header>
                }
            >
                <Textarea
                    id='email-template-body'
                    value={emailBody}
                    autoComplete={false}
                    placeholder="This is a placeholder"
                    readOnly
                    rows={14}
                    />
            </Container>
        );
    }

    return (
        <span>
            <Breadcrumbs {...props} items={items}></Breadcrumbs>
            <Grid
            gridDefinition={[{ colspan: 12 }, { colspan: 12 }]}
            >
                <div>
                    <Instructions />
                    <ParticipantsTable />
                </div>
                <div>
                    <EmailTemplate />
                </div>
            </Grid>
            <AddModal />
            <DeleteModal />
        </span>
    );
}

export default withFlashContext(Participants);
