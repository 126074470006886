import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from '../../PrivateRoute';
import List from './List';
import Participants from './Participants';
import View from './View';

class Routes extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
        <Router>
            <div>
                <PrivateRoute exact path="/questionnaires" component={List} />
                <PrivateRoute exact path="/questionnaires/:id" component={View} />
                <PrivateRoute path="/questionnaires/:id/assignments/:assignmentId" component={View} />
                <PrivateRoute path="/questionnaires/:id/participants" component={Participants} />
            </div>
        </Router>
        );
    }
}

export default Routes;