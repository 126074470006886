import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, TableSorting } from '@amzn/awsui-components-react';
import * as QuestionnaireApi from '../../api/QuestionnaireApi';
import CONSTANTS from '../../constants';
import { FlashContextInterface, withFlashContext } from '../../context/FlashContext';
import { FlashType } from '../../types/Flash.d';
import { Capitalize } from '../../Utility';
import { Icon } from '@amzn/awsui-components-react-v3';

class List extends React.Component<FlashContextInterface, any> {
    private readonly columnDefinitions: Table.ColumnDefinition<Table.Item>[];
    private readonly sortableColumns: TableSorting.SortableColumn<Table.Item>[];

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            questionnaires: []
        };

        this.columnDefinitions = [
            {
                id: "title",
                header: "Title",
                cell: (item:any) => (<Link to={`/questionnaires/${item.id}`}>{item.title}</Link>)
            },
            {
                id: "participants",
                header: "Participants",
                cell: (item: any) => item.assignments &&
                    (<span style={{ whiteSpace: "nowrap" }}>{item.assignments} {item.assignments > 1 ? 'participants' : 'participant'} <Link to={`/questionnaires/${item.id}/participants`}><Icon name="edit"></Icon></Link></span>),
            },
            {
                id: "requester",
                header: "Requester",
                cell: (item:any) => item.requester
            },
            {
                id: "description",
                header: "Description",
                cell: (item:any) => item.description
            },
            {
                id: "dueDate",
                header: "Due Date",
                cell: (item:any) => {
                    return item.dueDate ? (new Date(item.dueDate)).toDateString() : 'N/A';
                }
            },
            {
                id: "status",
                header: "Status",
                cell: (item:any) => {
                    return Capitalize(item.status === 'sent' ? 'ready' : item.status);
                }
            }
        ];

        this.sortableColumns = [
            { id: "title", field: "title" },
            { id: "participants", field: "participants" },
            { id: "requester", field: "requester" },
            { id: "description", field: "description" },
            { id: "dueDate", field: "dueDate" },
            { id: "status", field: "status" },
        ];
    }

    async componentDidMount() {
        await this.getQuestionnaires();
    }

    private getQuestionnaires = async () => {
        this.setState({
            loading: true
        });
        try {
            const q = await QuestionnaireApi.getQuestionnaires();
            this.setState({
                loading: false,
                questionnaires: q
            });
        } catch (e) {
            console.log(`Encountered error loading questionnaires: ${e instanceof Error ? e.message : "Exception thrown is not of type Error"}`);
            this.setState({
                loading: false,
                questionnaires: []
            });
            this.props.alert(FlashType.error, e instanceof Error ? e.message : "Exception thrown is not of type Error", "Error loading questionnaires. Please make sure the email used to assign the questionnaire matches exactly to your login email");
        }
    };

    private renderHeader = () => {
        return (
            <div className="awsui-util-action-stripe">
                <div className="awsui-util-action-stripe-title">
                    <h2>{CONSTANTS.QUESTIONNAIRE_TABLE_HEADER}</h2>
                </div>
                <div className="awsui-util-action-stripe-group">
                    <Button loading={this.state.loading} icon="refresh" onClick={() => this.getQuestionnaires()}></Button>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div>
                <Table
                    loading={this.state.loading}
                    columnDefinitions={this.columnDefinitions}
                    items={this.state.questionnaires}
                    header={this.renderHeader()}
                    empty={<div className="awsui-util-t-c">
                        <div className="awsui-util-pt-xs awsui-util-mb-xs">
                            <b>
                                {CONSTANTS.QUESTIONNAIRE_TABLE_NO_CONTENT_HEADER}
                            </b>
                        </div>
                        <p className="awsui-util-mb-s">
                            {CONSTANTS.QUESTIONNAIRE_TABLE_NO_CONTENT_BODY}
                        </p>
                    </div>}
                >
                    <TableSorting sortableColumns={this.sortableColumns}></TableSorting>
                </Table>
            </div>
        );
    }
}

export default withFlashContext(List);
