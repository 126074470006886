import React from 'react';
import Auth from "../Auth/Auth";

class Login extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }
    async componentDidMount() {
        if (!this.props.location.search) {
            this.props.history.push('/');
            return;
        }
        const loginInfo = await Auth.loginWithQueryParameters(this.props.location.search);
        if (loginInfo.state) {
            this.props.history.push(loginInfo.state);
        } else {
            this.props.history.push('/');
        }
    }

    render() {
        return <div>Loading</div>;
    }
}

export default Login;