export function IsString(obj: any): boolean {
    return (typeof obj === 'string' || obj instanceof String);
}

export function IsURL(input: string): boolean {
    if (!IsString(input)) {
        return false;
    }
    try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const url = new URL(input);
        return true;
    } catch (e) {
        return false;
    }
}

export function Capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function IsUndefinedOrNull(value: any): boolean {
    return (value === undefined || value === null);
}
