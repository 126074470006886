import { CookieConsentManager } from '@amzn/awscat-cookie-management';
/*
 * Shortbread Integration for EU Cookie compliance: https://w.amazon.com/bin/view/Shortbread/usage
 *
 * It will only be invoked when shortbread's geoip location detects traffic from EU.
 * For testing, use AnyConnect VPN and connect to an EU endoint such as 'Frankfurt, DE', and
 */
const cookieManager = new CookieConsentManager({
    domain: window.location.hostname,
    parent: document.getElementById('root')!,
});
export default cookieManager;
