import React from 'react';
import './Header.css';
import Auth from '../../Auth/Auth';

class Header extends React.Component<any, any> {
    logout(event: any) {
        event.preventDefault();
        Auth.redirectToLogout();
    }

    login(event: any) {    
        event.preventDefault();
        Auth.redirectToLogin();
    }

    renderLogin() {
        return <a onClick={this.login}>Log in</a>;
    }

    renderLogout() {
        return <a onClick={this.logout}>Log out</a>;
    }

    renderLogo() {
        return (
            <div className={"col-xs-5 col-s-5 col-m-5 col-l-5 col-xxs-12 header-title-container"}>
                <div className="header-logo-item">
                    <a href="/">
                        <div className="header-logo">AWS</div>
                    </a>
                </div> 
            </div>
        );
    }

    renderHeaderNav() {
        const alert = this.context.alert;
        return (
            <div className={"col-xs-7 col-s-7 col-m-7 col-l-7 col-xxs-12 header-nav-container"}>
                <div className="header-nav-item">
                    {!this.props.authenticated ? this.renderLogin() : this.renderLogout()}
                </div>
            </div>
         );
        
    }

    render() {
        return (
            <header id="h" className={'awsui-grid'}>
                <div className={"awsui-container"}>
                    <div className={"awsui-row awsui-util-no-gutters header-container"}>
                        {this.renderLogo()}
                        {this.renderHeaderNav()}
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
