import axios from 'axios';
import jws from 'jws';
import jwkToPem from 'jwk-to-pem';
import moment from 'moment';
import queryString from 'query-string';
import { AuthInfo } from './AuthInfo';
import { TokenInfo } from './TokenInfo';
import { LoginInfo } from './LoginInfo';
import { UserInfo } from './UserInfo';
import Config from '../Config';
import CatPortalService from '../Services/CatPortalService';

export type AuthenticationCallback = (authenticated: boolean) => void;

export class Authentication {
    token: TokenInfo | null;
    user: UserInfo | undefined;
    callback: AuthenticationCallback | undefined;

    constructor() {
        this.token = null;
        console.log("Auth instance created");
    }

    private invokeCallback() {
        if (this.callback) {
            this.callback(this.isAuthenticated());
        }
    }

    private getSignoutQueryParameters(currentLocation: Location) {
        if (!currentLocation) {
            throw new Error("Cannot create login query parameters without redirectionLocation");
        }
        const protocol: string = currentLocation.protocol;
        const hostname: string = currentLocation.hostname;
        const port: string = currentLocation.port ? `:${currentLocation.port}` : '';

        const redirectUri: string = `${protocol}//${hostname}${port}/signout`;

        const queryParams: any = {
            redirectUri: redirectUri
        };

        const queryString = Object.keys(queryParams)
            .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryParams[k])}`)
            .join("&");

        return queryString;
    }

    private getLoginQueryParameters(currentLocation: Location) {
        if (!currentLocation) {
            throw new Error("Cannot create login query parameters without redirectionLocation");
        }
        const protocol: string = currentLocation.protocol;
        const hostname: string = currentLocation.hostname;
        const port: string = currentLocation.port ? `:${currentLocation.port}` : '';

        const redirectUri: string = `${protocol}//${hostname}${port}/login`;

        console.log(`Environment: ${process.env.NODE_ENV}`);
        const queryParams: any = {
            redirectUri: redirectUri,
            appId: Config.IsDevelopmentEnvironment() ? 'mdc-localdev' : 'mdc',
            state: currentLocation.pathname + currentLocation.search,
        };

        const queryString = Object.keys(queryParams)
            .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryParams[k])}`)
            .join("&");

        return queryString;
    }

    private getLoginUrl(currentLocation: Location): string {
        const authenticationUrl = `${Config.CatPortalUrl()}/sso`
        const queryParameters = this.getLoginQueryParameters(currentLocation);
        const loginUrl = `${authenticationUrl}?${queryParameters}`;
        return loginUrl; 
    }

    private getLogoutUrl(currentLocation: Location): string {
        const queryParameters = this.getSignoutQueryParameters(currentLocation);
        return `${Config.CatPortalUrl()}/portal/signout?${queryParameters}`;
    }

    private async getJWKS() {
        const res = await CatPortalService.get('/jwks', {
            withCredentials: true
        });
        return res.data;
    }

    private async verifyToken(token: string): Promise<AuthInfo> {
        try {
            const jwt = jws.decode(token);
    
            if (!(jwt && jwt.payload)) {
                throw new Error('Missing token payload');
            }
    
            const payload = JSON.parse(jwt.payload);

            const jwks = await this.getJWKS();
            const certs = jwks.keys.map((jwk:any) => jwkToPem(jwk));
            const verified = certs.some((c: any) => jws.verify(token, jwt.header.alg, c));
    
            if(!verified) {
                throw new Error('Failed to verify token against JWKS.');
            }
    
            const tokenExpiryDate = moment(payload.exp * 1000).utc().format();
            /*
            const { data: userProfile } = await catPortalService.get('/users/current', {
                withCredentials: true
            });
            */
    
            const authInfo = {
                tokenInfo: new TokenInfo(token, tokenExpiryDate),
                userInfo: {
                    email: payload.email,
                },
                //userProfile: userProfile
            };

            return authInfo;
        } catch(err) {
            console.error(err);
            throw err;
        }
    }

    public async loginWithQueryParameters(queryParameters: string): Promise<LoginInfo> {
        console.log("logging in");
        const queryParams = queryString.parse(queryParameters);
        const token = queryParams.id_token;
        const state = (!Array.isArray(queryParams.state) && (queryParams.state !== null) && (queryParams.state !== '')) ? queryParams.state : undefined;
        if (!token) {
            throw new Error("Authentication Token not found");
        } else if (Array.isArray(token)) {
            throw new Error("Authentication Token must be a string");
        }
        const authInfo = await this.verifyToken(token);
        this.token = authInfo.tokenInfo;
        this.user = authInfo.userInfo;

        this.invokeCallback();

        const loginInfo = {
            authInfo: authInfo,
            state: state
        }

        return loginInfo;
    }

    public async refreshToken(): Promise<AuthInfo> {
        console.log("Refreshing token");
        // This required due to a bug in axios that propagates instance defaults settings to global defaults
        // https://github.com/axios/axios/pull/1391

        const commonHeaders = Object.assign({}, axios.defaults.headers.common);
        try {
            const appId = Config.IsDevelopmentEnvironment() ? 'mdc-localdev' : 'mdc';
            const res = await CatPortalService.get(`/sso?appId=${appId}`);
            const authInfo = await this.verifyToken(res.data);
            this.token = authInfo.tokenInfo;
            this.user = authInfo.userInfo;

            this.invokeCallback();

            return authInfo
        } catch (e) {
            console.log("Unable to refresh token, full login required");
            console.log(e);

            this.invokeCallback();

            throw e;
        } finally {
            axios.defaults.headers.common = commonHeaders;
        }
    }

    public redirectToLogout(): void {
        console.log("Redirecting for logout");
        const logoutUrl = this.getLogoutUrl(window.location);
        console.log(`Redirecting to ${logoutUrl}`);
        window.location.replace(logoutUrl);
    }

    public redirectToLogin(): void {
        console.log("Redirecting for login");
        const loginUrl = this.getLoginUrl(window.location);
        console.log(`Redirecting to ${loginUrl}`);
        window.location.replace(loginUrl);
    }

    public async authenticate(): Promise<AuthInfo> {
        try {
            const authInfo = await this.refreshToken();
            return authInfo;
        } catch (e) {
            this.redirectToLogin();
            throw e;
        }
    }

    public isAuthenticated(): boolean {
        return (this.token !== null);
    }

    public getToken(): string {
        if (!this.token) {
            throw new Error("Not Authenticated");
        }

        return this.token.token;
    }

    public getProvider(): string {
        return 'catportal';
    }

    public setCallback(callback: AuthenticationCallback) {
        this.callback = callback;
    }

    public getUserEmail(): string {
        if (!this.isAuthenticated()) {
            throw new Error('Not Authenticated');
        }

        if (!this.user?.email) {
            throw new Error('Missing email');
        }
        return this.user?.email;
    }
}

const auth = new Authentication();

export default auth;