export enum FlashType {
    success = "success",
    error = "error",
    warning = "warning",
    info = "info",
}

export interface Flash {
    type: FlashType;
    header: string;
    content: string;
    id: string;
}
