import { IsURL } from './Utility';

class Configuration {

    dataCollectionServiceEndpoint: string;
    catPortalUrl: string;
    copyrightYears: string;
    version: string;

    constructor() {
        if (!process.env.REACT_APP_CAT_PORTAL_URL) {
            throw new Error("CAT Portal URL not set")
        } else if (!IsURL(process.env.REACT_APP_CAT_PORTAL_URL)) {
            throw new Error(`CAT Portal URL is not a valid URL: ${process.env.REACT_APP_CAT_PORTAL_URL}`)
        }

        let tempDataCollectionServiceEndpoint = process.env.REACT_APP_SERVICE_ENDPOINT;
        if (!tempDataCollectionServiceEndpoint) {
            throw new Error("Data Collection Service URL not set");
        } else if (!IsURL(tempDataCollectionServiceEndpoint)) {
            console.log(`Valid protocol not specified in service endpoint url: ${tempDataCollectionServiceEndpoint}, inferring protocol`);
            tempDataCollectionServiceEndpoint = (process.env.NODE_ENV === 'development') ?
                `${window.location.protocol}//${tempDataCollectionServiceEndpoint}` :
                `https://${tempDataCollectionServiceEndpoint}`;
            if (!IsURL(tempDataCollectionServiceEndpoint)) {
                throw new Error(`Service endpoint with inferred protocol is not a valid url: ${tempDataCollectionServiceEndpoint}`);
            }
        }

        this.dataCollectionServiceEndpoint = tempDataCollectionServiceEndpoint;
        this.catPortalUrl = process.env.REACT_APP_CAT_PORTAL_URL;
        this.copyrightYears = (process.env.REACT_APP_COPYRIGHT_YEARS && process.env.REACT_APP_COPYRIGHT_YEARS.length) ?
            process.env.REACT_APP_COPYRIGHT_YEARS :
            `2009 - ${new Date().getFullYear()}`
        this.version = (process.env.REACT_APP_BUILD_VERSION && process.env.REACT_APP_BUILD_VERSION.length) ?
            process.env.REACT_APP_BUILD_VERSION :
            `${process.env.NODE_ENV}` ;
    }

    public DataCollectionServiceEndpoint(): string {
        if (!this.dataCollectionServiceEndpoint.length) {
            throw new Error("Data Collection Service URL not set")
        }
        return this.dataCollectionServiceEndpoint;
    }

    public CatPortalUrl(): string {
        if (!this.catPortalUrl.length) {
            throw new Error("Cat Portal URL not set");
        }
        return this.catPortalUrl;
    }

    public CopyrightYears(): string {
        if (!this.copyrightYears.length) {
            throw new Error("Copyright Years not set");
        }
        return this.copyrightYears;
    }

    public Version(): string {
        if (!this.version.length) {
            throw new Error("Build Version not set");
        }
        return this.version;
    }

    public IsDevelopmentEnvironment(): boolean {
        return process.env.NODE_ENV === 'development';
    }

    public ContactUsMailTo(): string {
        return 'aws-accelerate@amazon.com'
    }
}

const config = new Configuration();

export default config;
