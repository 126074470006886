import MDCService from '../Services/MDCService';
import { Answer } from '../types/Answer';
import { Assignment } from '../types/Assignment';
import { Questionnaire } from '../types/Questionnaire';

export async function getQuestionnaire(id: string): Promise<Questionnaire> {
    console.log("Getting questionnaire");
    const res = await MDCService.get(`/questionnaires/${id}`);
    console.log("Finished getting questionnaire");
    if (typeof res.data !== 'object' || res.data === null) {
        throw new Error(`Service response did match expected type, expected: ${typeof Object}, actual: ${typeof res.data}`);
    }
    return res.data;
}

export async function getQuestionnaires(): Promise<Questionnaire[]> {
    console.log("Getting questionnaires");
    const res = await MDCService.get('/questionnaires/assigned');
    console.log("Finished getting questionnaires");
    if (!Array.isArray(res.data)) {
        throw new Error(`Service response did match expected type, expected: ${typeof Array}, actual: ${typeof res.data}`);
    }
    return res.data;
}

export async function getQuestions(id: string, assignmentId?: string): Promise<Questionnaire> {
    console.log(`Getting questions for questionnaire: ${id}`);
    const res = assignmentId ? await MDCService.get(`/questionnaires/${id}/assignments/${assignmentId}`) :
        await MDCService.get(`/questionnaires/${id}/questions`) ;
    console.log(`Finished getting questionnaire: ${id}`);
    if (typeof res.data !== 'object' || res.data === null) {
        throw new Error(`Service response did match expected type, expected: ${typeof Object}, actual: ${typeof res.data}`);
    }
    return res.data;
}

export async function answerQuestions(id: string, answers: Answer[]): Promise<Questionnaire> {
    console.log(`Answering ${answers.length} questions for questionnaire: ${id}`);
    const res = await MDCService.post(`/questionnaires/${id}/answers`, answers);
    console.log(`Finished posting answers for questionnaire: ${id}`);
    if (typeof res.data !== 'object' || res.data === null) {
        throw new Error(`Service response did match expected type, expected: ${typeof Object}, actual: ${typeof res.data}`);
    }
    return res.data;
}

export async function submitQuestions(id: string, answers: Answer[]): Promise<Questionnaire> {
    console.log(`Submitting questionnaire: ${id}`);
    const res = await MDCService.post(`/questionnaires/${id}/submit`, answers);
    console.log(`Finished submitting questionnaire: ${id}`);
    if (typeof res.data !== 'object' || res.data === null) {
        throw new Error(`Service response did match expected type, expected: ${typeof Object}, actual: ${typeof res.data}`);
    }
    return res.data;
}

export async function getParticipants(id: string): Promise<Assignment[]> {
    console.log(`Getting assignments of the questionnaire: ${id}`);
    const res = await MDCService.get(`/questionnaires/${id}/assignments`);
    console.log(`Finished getting assignments: ${id}`);
    if (!Array.isArray(res.data)) {
        throw new Error(`Service response did match expected type, expected: ${typeof Array}, actual: ${typeof res.data}`);
    }
    return res.data;
}

export async function createParticipants(id: string, userEmails: string[]): Promise<Assignment[]> {
    console.log(`Creating assignments of the questionnaire: ${id}`);
    const res = await MDCService.post(`/questionnaires/${id}/assignments/batchCreate`, userEmails);
    console.log(`Finished creating assignments: ${id}`);
    if (!Array.isArray(res.data)) {
        throw new Error(`Service response did match expected type, expected: ${typeof Array}, actual: ${typeof res.data}`);
    }
    return res.data;
}

export async function deleteParticipants(id: string, userEmails: string[]): Promise<Assignment[]> {
    console.log(`Deleting assignments of the questionnaire: ${id}`);
    const res = await MDCService.post(`/questionnaires/${id}/assignments/batchDelete`, userEmails);
    console.log(`Finished deleting assignments: ${id}`);
    if (!Array.isArray(res.data)) {
        throw new Error(`Service response did match expected type, expected: ${typeof Array}, actual: ${typeof res.data}`);
    }
    return res.data;
}
