import { BreadcrumbGroup } from "@amzn/awsui-components-react";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

interface BreadcrumbsProp {
    items: BreadcrumbGroup.Item[];
}

interface MatchParams {
    id: string;
}

class Breadcrumbs extends React.Component<RouteComponentProps<MatchParams> & BreadcrumbsProp> {
    public render() {
        const { items } = this.props;
        return (
            <div className='awsui-util-pb-l'>
                <BreadcrumbGroup items={items} onClick={this.navigate}  />
            </div>
        );
    }

    private navigate = (e: any) => {
        e.preventDefault();
        this.props.history.push(e.detail.href);
    }
}

export default Breadcrumbs;