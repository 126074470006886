import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Auth from '../Auth/Auth';
import Config from '../Config';

const HEADERS = {
    AUTH_PROVIDER: 'x-auth-provider'
}

export class MDCService {
    private service: AxiosInstance;

    constructor() {
        console.log(`Creating service connected to ${Config.DataCollectionServiceEndpoint()}`);
        this.service = axios.create({
            baseURL: Config.DataCollectionServiceEndpoint(),
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
        });

        this.service.interceptors.request.use(async (config: AxiosRequestConfig) => {
            const authToken = await Auth.getToken();
            config.headers.Authorization = `Bearer ${authToken}`;
            config.headers[HEADERS.AUTH_PROVIDER] = Auth.getProvider();
            return config;
        }, undefined);

        this.service.interceptors.response.use(undefined, async (err: any) => {
            console.log("MDCService response error intercepted.");
            if (err && err.config && err.response && err.response.status && err.response.status === 401) {
                const authInfo = await Auth.refreshToken();
                err.config.headers.Authorization = authInfo.tokenInfo.token;
                err.config.headers[HEADERS.AUTH_PROVIDER] = Auth.getProvider();

                return axios.request(err.config);
            } else {
                throw err; // throwing will return a rejected promise since this is an async function
            }
        });
    }

    public async get(url: string, config?: AxiosRequestConfig | undefined) {
        return this.service.get(url, config)
    }

    public async post(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
        return this.service.post(url, data, config);
    }
}

const mdcService = new MDCService();

export default mdcService;
