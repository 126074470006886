import { LegalFooter } from '@amzn/awscat-cookie-management';
import React from 'react';
import './Footer.css';
import cookieManager from '../../common/CookieManager';
import Config from '../../Config';

class Footer extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    getCopyrightYears(): string {
        return Config.CopyrightYears();
    }

    getBuildVersion(): string {
        return Config.Version();
    }

    render() {
        return (
            <LegalFooter
                privacyText='Privacy'
                siteTermText='Site terms'
                siteTermHref='/terms'
                cookiePreferenceText='Cookie preferences'
                onCookiePreferenceClick={cookieManager.customizeCookies}
                contactEmail={Config.ContactUsMailTo()}
                buildVersion={this.getBuildVersion()}
            />
        );
    }
}

export default Footer;
