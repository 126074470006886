import React from 'react';

class Terms extends React.Component<any, any> {
    render() {
        return (
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">
                    <h1>Site terms</h1>
                </div>
                <div>
                    This AWS Content is provided subject to the terms of the AWS Customer Agreement available at <a href="http://aws.amazon.com/agreement">http://aws.amazon.com/agreement</a> or other written agreement between Customer and Amazon Web Services, Inc.
                </div>
            </div>
        )
    }
}

export default Terms;
