import React from 'react';
import { Flash, FlashType } from '../types/Flash';

export type Alert = (type: FlashType, content: string, header: string) => void;

export interface FlashContextInterface {
    flashes: Flash[];
    alert: Alert;
    remove: (id: string) => void;
}

export const FlashContext = React.createContext<FlashContextInterface>({alert: () => {}, remove: () => {}, flashes:[]});

export function withFlashContext(Component: any) {
    return function FlashContextComponent(props: any) {
        return (
            <FlashContext.Consumer>
                {ctx => <Component {...props} flashes={ctx.flashes} remove={ctx.remove} alert={ctx.alert}/>}
            </FlashContext.Consumer>);
    }
}
