import { Flash } from '@amzn/awsui-components-react';
import React from 'react';
import { FlashContextInterface, withFlashContext } from '../../context/FlashContext';

class FlashMessages extends React.Component<FlashContextInterface> {
    constructor(props: FlashContextInterface) {
        super(props);
    }

    dismiss = (event: CustomEvent) => {
        if (event.target && (event.target as HTMLElement).id) {
            const id = (event.target as HTMLElement).id;
            this.props.remove(id);
        }
    }

    render() {
        const flashes = this.props.flashes;
        return flashes.map((flash) => {
            return <Flash type={flash.type} content={flash.content} header={flash.header} id={flash.id} key={flash.id} dismissLabel="Dismiss message" dismissible={true} onDismiss={this.dismiss} />
        });
    }
}

export default withFlashContext(FlashMessages);
