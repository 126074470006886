import axios from 'axios';
import Config from '../Config';

export const catPortalService = axios.create({
    baseURL: `${Config.CatPortalUrl()}`,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true
});

export default catPortalService;
