import React, { Component } from 'react';
import { AppLayout } from '@amzn/awsui-components-react';
import './App.css';
import Auth from './Auth/Auth';
import Header from './components/common/Header';
import FlashMessages from './components/common/FlashMessages';
import FlashMessagesProvider from './components/common/FlashMessagesProvider';
import Footer from './components/common/Footer';
import Navigation from './components/common/Navigation';
import Routes from './Routes';
import cookieManager from './common/CookieManager';

class App extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      auth: {
        isAuthenticated: false,
      }
    }
  }

  setAuthState(authenticated: boolean) {
    this.setState({
      auth: {
        isAuthenticated: authenticated
      }
    });
  }

  authCallback = (authenticated: boolean) => {
    this.setAuthState(authenticated);
  }

  async componentDidMount() {
    Auth.setCallback(this.authCallback);
    this.setAuthState(Auth.isAuthenticated());
    cookieManager.checkForCookieConsent();
  }

  async componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    //Auth.setCallback(this.authCallback);
    //this.setAuthState(Auth.isAuthenticated());
  }


  private renderContents () {
    return (
      <Routes />
    );
  }

  render() {
    return (
      <div id="b" className="App">
        <FlashMessagesProvider>
          <Header authenticated={this.state.auth.isAuthenticated}/>
          <FlashMessages />
          <AppLayout
            content={this.renderContents()}
            footerSelector={'#b #page-footer'}
            navigation={<Navigation />}
            navigationOpen={false}
            navigationWidth={250}
            toolsHide={true}
          />
          <Footer />
        </FlashMessagesProvider>
      </div>
    );
  }
}

export default App;
