import React from 'react';
import { ColumnLayout, FormField, Icon } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import config from '../Config';

class Landing extends React.Component<any, any> {
  render() {
    return (
    <div className='awsui-grid awsui-util-p-s'>
      <div className='custom-home__header custom-awsui-util-pt-xxxl awsui-row'>
        <div className='col-xxs-12'>
          <div className='awsui-row'>
            <div className='custom-home__category col-xxs-10 offset-xxs-1 col-l-8 offset-l-2'>
              Customer Interaction and Workflow Acceleration
            </div>
          </div>
          <div className='awsui-row'>
            <div className='custom-home__header-title col-xxs-10 offset-xxs-1 col-s-6 col-l-5 offset-l-2 col-xl-6'>
              <div className='awsui-text-large'>
                <h1 className='awsui-text-large'><strong>Business Data Gathering</strong></h1>
                <div>automated, fast, and reliable data collection</div>
              </div>
              <p>
                The Business Data Gathering tool increases accountability, reduces manual efforts, brings consistency, and accelerates the data collection workflow in a secure manner.
              </p>
            </div>
            <div className='col-xxs-10 offset-xxs-1 col-s-4 offset-s-0 col-l-3 col-xl-2 custom-home__header-sidebar'>
              <div className='awsui-util-container awsui-util-mb-n'>
                <h2>View Questionnaires</h2>
                <FormField stretch={true} className='awsui-util-d-b'>
                  <Link to='/questionnaires' className='awsui-button awsui-button-variant-primary awsui-hover-child-icons'>Start Here</Link>
                </FormField>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='awsui-util-pt-xxl awsui-row'>
        <div className='custom-home-main-content-area col-xxs-10 offset-xxs-1 col-s-6 col-l-5 offset-l-2 col-xl-6'>
          <h2 className='custom-home-section-header'>Benefits and features</h2>
          <div className='awsui-util-container'>
            <div>
              <ColumnLayout columns={2} variant='text-grid'>
                <div data-awsui-column-layout-root={true}>
                  <div>
                    <h3 className='awsui-util-pt-n'>Easily Answer Questionnaires</h3>
                    <p>
                      Makes it easy to see what questionnaires are assigned to you and their status.
                    </p>
                  </div>
                  <div>
                    <h3 className='awsui-util-pt-n'>Automatic status notifications</h3>
                    <p>
                      Automatically notifies questionnaire creator of your status filling out the questionnaire.
                    </p>
                  </div>
                  <div>
                    <h3 className='awsui-util-pt-n'>Cross application integration</h3>
                    <p>
                      Integrated with the Portfolio Assessment tool
                    </p>
                  </div>
                  <div>
                    <h3 className='awsui-util-pt-n'>Data Notification</h3>
                    <p>
                      Get notified when your questionnaires have been answered.
                      Have data pushed back to your application
                    </p>
                  </div>
                </div>
              </ColumnLayout>
            </div>
          </div>

          <h2 className='custom-home-section-header'>Use cases</h2>
          <div className='awsui-util-container'>
            <div>
              <ColumnLayout columns={2} variant='text-grid'>
                <div data-awsui-column-layout-root={true}>
                  <div>
                    <h3 className='awsui-util-pt-n'>Integrate with Portfolio Assessment</h3>
                    <p>
                      Create questionnaires from the Portfolio Assessment tool and
                      have data pushed back to into your portfolio.
                    </p>
                  </div>
                  <div>
                    <h3 className='awsui-util-pt-n'>Let customers provide data on their own time</h3>
                    <p>
                      Customers answers questions directly through the tool to expedite workflows.
                    </p>
                  </div>
                </div>
              </ColumnLayout>
            </div>
          </div>

          <div className='awsui-util-container'>
            <div className='awsui-util-container-header'>
              <h2>Related services</h2>
            </div>
            <div>
              <ColumnLayout columns={2} variant="text-grid">
                <div data-awsui-column-layout-root={true}>
                  <div>
                    <h3 className='awsui-util-pt-n'><a href="https://mpa-proserve.amazonaws.com">Portfolio Assessment</a></h3>
                    <p>Assess your IT portfolio for migration. Build your business case.</p>
                  </div>
                  <div>
                    <h3 className='awsui-util-pt-n'><a href="http://mpl-proserve.amazonaws.com">Migration Pattern Library</a></h3>
                    <p>View existing patterns of migration to AWS</p>
                  </div>
                  <div>
                    <h3 className='awsui-util-pt-n'><a href="https://mra-proserve.amazonaws.com">Migration Readiness Assessment</a></h3>
                    <p>Determine how ready you are to migrate your IT portfolio</p>
                  </div>
                </div>
              </ColumnLayout>
            </div>
          </div>
        </div>
        <div className='custom-home__sidebar col-xxs-10 offset-xxs-1 col-s-4 offset-s-0 col-l-3 col-xl-2'>
          <div className='awsui-util-mt-xxl'>
            <div className='awsui-util-container'>
              <div className='awsui-util-container-header'>
                <h2>More resources
                  <Icon name='external' />
                </h2>
              </div>
              <div>
                <div className='custom-inner-table-row'>
                  <Link to='/faq'>FAQ</Link>
                </div>
                <div className='custom-inner-table-row'>
                  <a href={`mailto:${config.ContactUsMailTo()}`}>Contact us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default Landing;
