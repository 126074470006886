import { Button } from '@amzn/awsui-components-react';
import React from 'react';
import { Link } from 'react-router-dom';
import Auth from '../Auth/Auth';

class Logout extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        if (Auth.isAuthenticated()) {
            this.props.history.push('/');
        }
    }

    clickLogout(event: any) {
        event.preventDefault();
        Auth.redirectToLogout();
    }

    clickLogin(event: any) {
        event.preventDefault();
        Auth.redirectToLogin();
    }

    renderLoggedOut() {
        return (
            <div>
                <h1>You are logged out of Business Data Gathering</h1>
                <div>
                    If you want to log back in, please click <a onClick={this.clickLogin}>Log in</a>.
                </div>
            </div>
        );
    }

    renderLoggedIn() {
        return (
            <div>
                <h1>You are logged into Business Data Gathering</h1>
                <div>
                    <Button>
                        <Link to={`/questionnaires`}>Go to Questionnaires</Link>
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        return Auth.isAuthenticated() ? this.renderLoggedIn() : this.renderLoggedOut();
    }
}

export default Logout;
