import React from 'react';
import { SideNavigation } from '@amzn/awsui-components-react';

const header = { text: 'Business Data Gathering', href: '/' };

const items = [
  {
    type: 'section',
    text: 'Questionnaires',
    items: [
      { type: 'link', text: 'View Questionnaires', href: '/questionnaires' },
    ]
  },
];

export class Navigation extends React.Component {
  /*
  onFollowHandler(ev) {
    // keep the locked href for our demo pages
    ev.preventDefault();
  }*/

  render() {
    return (
      <SideNavigation
        items={items}
        header={header}
      />
    );
  }
}

export default Navigation
