import React from 'react';
import { FlashContext } from '../../context/FlashContext';
import { Flash, FlashType } from '../../types/Flash';

interface FlashMessagesProviderState {
    flashes: Flash[];
}

class FlashMessagesProvider extends React.Component<any, FlashMessagesProviderState> {
    constructor(props: any) {
        super(props);
        
        this.state = {
            flashes: []
        }
    }

    alert = (type: FlashType, content: string, header: string) => {
        const flash: Flash = {type: type, content: content, header: header, id: new Date().toISOString()};
        console.log(`Adding flash with id: ${flash.id}`);
        const updatedFlashes = this.state.flashes.concat(flash);
        this.setState({
            flashes: updatedFlashes
        });
    }

    remove = (id: string) => {
        let itemFiltered: boolean = false;
        const currentFlashes = this.state.flashes;
        const updatedFlashes = currentFlashes.filter((elem: Flash) => {
            const filtered = (elem.id !== id);
            if (filtered) {
                itemFiltered = true;
                console.log(`Removing flash with id: ${id}`);
            }
            return filtered;
        });

        if (itemFiltered) {
            this.setState({
                flashes: updatedFlashes
            });
        } else {
            console.log(`Could not remove flash with id: ${id}`);
        }
    }

    render() {
        const children = this.props.children;

        return (
            <FlashContext.Provider
                value={{
                    alert: this.alert,
                    remove: this.remove,
                    flashes: this.state.flashes
                }}
            >
                {children}
            </FlashContext.Provider>
        );
    }
};

export default FlashMessagesProvider;

