import React from 'react';

interface FAQItem {
    question: string;
    answer: string;
}

const FAQs: FAQItem[] = [
    {
        question: `What is Business Data Gathering?`,
        answer: `
        The Objective of the Business Data Gathering tool is to increase accountability, reduce manual efforts, bring consistency and accelerate the data collection workflow in a secure manner. 
        The tool allows you to create a questionnaire template where every question in the template can have an explanation, answer type, answers list, validation check, order, and criticality.
        The user can then start the data collection workflow by sending one or more questionnaire templates to multiple customer contacts at the same time.
        The tool's notification service informs the customer contact whenever a questionnaire is assigned to him/her.
        `,
    },
    {
        question: `How can I add a new contact?`,
        answer: `
        The Business Data Gathering Tool sends an account creation request whenever the 1st questionnaire is sent to a new user. 
        New users can follow the sign-up process defined in an email to set-up the account and once account is set-up, the questionnaire will automatically be shown to the new user.
        New users can also click on log in and follow the sign-up process to create a new account in the Business Data Gathering Tool.  
        `,
    },
    {
        question: `How is a questionnaire created?`,
        answer: `Questionnaires are created through applications that integrate with the Business Data Gathering Tool, such as Portfolio Assessment.`
    },
    {
        question: `Is there an upper limit to the number of questionnaires per user?`,
        answer: `There is no limit on the number of questionnaires per user.`,
    },
    {
        question: `How can I reset my password?`,
        answer: `Click on forget password on Sign-In page to reset your password.`,
    },
    {
        question: `Can I re-assign a questionnaire to somebody else?`,
        answer: `
        You can’t re-assign the questionnaire.
        Though the service that was used to create the questionnaire can re-assign the questionnaire and re-send a new request.
        Once a new request is sent, the questionnaire will not appear when you log in to the Business Data Gathering tool.
        `,
    },
    {
        question: `What happens when I submit a questionnaire?`,
        answer: `The Business Data Gathering tool calls the service that generated the questionnaire and updates it with newly submitted answers.`,
    },
    {
        question: `Can I delete a previously answered questionnaire`,
        answer: `No, you can delete the previously answered questionnaires but you can resubmit the questionnaire with new values.`,
    }
]

class FAQ extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">
                    <h1>FAQ</h1>
                </div>
                <div>
                    <ul>
                        {FAQs.map((item: FAQItem) => {
                            return (
                                <li>
                                    <h4>{item.question}</h4>
                                    <p>{item.answer}</p>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

export default FAQ;
